import styles from "./screens.module.scss";

import SubHeader from "../components/subHeader/SubHeader";
import PrivacyPolicyData from "../config/constants/PrivacyPolicy.json";

export default function PrivacyPolicy() {
  return (
    <div className={styles["container"]}>
      <SubHeader title="Privacy Policy" />

      <div className={styles["body"]}>
        {PrivacyPolicyData.Privacy.map((term) =>
          term.type === "header" ? (
            <h2>{term.value} </h2>
          ) : term.type === "paragraph" ? (
            <p>{term.value} </p>
          ) : term.type === "subHeader" ? (
            <span>{term.value} </span>
          ) : (
            ""
          )
        )}
      </div>
    </div>
  );
}
