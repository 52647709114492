import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={styles["footer-container"]}>
      <hr className={styles["hr"]} />

      <div className={styles.curawella}>
        <a href="https://curawella.com" target="_blank" rel="noreferrer">
          <img src="/assets/curawella.png" alt="curawella.com" />
        </a>

        <p type="p2">{"جميع الحقوق محفوظة"}</p>
      </div>
    </div>
  );
};

export default Footer;
