import "./App.scss";

import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import Footer from "./components/footer/Footer";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsOfUse from "./screens/TermsOfUse";

function App() {
  return (
    <Router>
      <div className="app-container">
        <Switch>
          <Route
            exact
            path="/privacy-policy"
            component={() => <PrivacyPolicy />}
          />

          <Route exact path="/terms-of-use" component={() => <TermsOfUse />} />
          <Redirect to={"/privacy-policy"} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
