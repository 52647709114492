import styles from "./SubHeader.module.scss";

type SubHeaderProps = { title: string };

const SubHeader: React.FC<SubHeaderProps> = ({ title }) => {
  return (
    <div className={styles["sub-header-container"]}>
      <h1>{title}</h1>
    </div>
  );
};

export default SubHeader;
